/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React, { Suspense, lazy } from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import ReSimpleCircularProgress from 'components/ReSimpleCircularProgress';
import PageLoadMeasure from 'components/PageLoadMeasure';
import ListPath from './pages/ListPath';

const GeneralPage = lazy(() => import('./pages/GeneralPage'));
const SalesSummaryPage = lazy(() =>
  import('./pages/SalesSummary/SalesSummaryPage'),
);
const DetailSummaryPage = lazy(() => import('./pages/details/DetailSummary'));
const DetailSalesGroupPage = lazy(() =>
  import('./pages/details/DetailSalesGroupPage'),
);
const DetailSalesDepartmentPage = lazy(() =>
  import('./pages/details/DetailSalesDepartmentPage'),
);
const DetailSalesPackagePage = lazy(() =>
  import('./pages/details/DetailSalesPackagePage'),
);
const DetailSalesProductPage = lazy(() =>
  import('./pages/details/DetailSalesProductPage'),
);
const DetailSalesPettyCash = lazy(() =>
  import('./pages/details/DetailSalesPettyCash'),
);
const RefundVoidSummary = lazy(() =>
  import('./pages/details/RefundVoidSummary'),
);
const RefundVoidDetails = lazy(() =>
  import('./pages/details/RefundVoidDetails'),
);
const DetailVoidReport = lazy(() => import('./pages/details/DetailVoidReport'));
const TransactionListPage = lazy(() =>
  import('./pages/details/TransactionListPage'),
);
const TransactionDetailPage = lazy(() =>
  import('./pages/details/TransactionDetailPage'),
);

const SendPostMessage = lazy(() => import('./pages/DevTools/SendPostMessage'));
const ListenPostMessage = lazy(() =>
  import('./pages/DevTools/ListenPostMessage'),
);
const DummyFetch = lazy(() => import('./pages/DevTools/DummyFetch'));
const DeployInfo = lazy(() => import('./pages/DevTools/DeployInfo'));

export const RoutePaths = [
  { path: '/general', element: <GeneralPage />, noPageLoad: true },
  { path: '/sales-summary', element: <SalesSummaryPage />, loadTime: 4 },
  { path: '/detail-summary', element: <DetailSummaryPage />, loadTime: 4 },
  {
    path: '/detail-sales-group',
    element: <DetailSalesGroupPage />,
    loadTime: 4,
  },
  {
    path: '/detail-sales-department',
    element: <DetailSalesDepartmentPage />,
    loadTime: 4,
  },
  {
    path: '/detail-sales-product',
    element: <DetailSalesProductPage />,
    loadTime: 4,
  },
  {
    path: '/detail-sales-package',
    element: <DetailSalesPackagePage />,
    loadTime: 4,
  },
  {
    path: '/detail-sales-petty-cash',
    element: <DetailSalesPettyCash />,
    loadTime: 4,
  },
  { path: '/void-refund-summary', element: <RefundVoidSummary />, loadTime: 4 },
  { path: '/void-refund-details', element: <RefundVoidDetails />, loadTime: 4 },
  { path: '/void-details', element: <DetailVoidReport />, loadTime: 4 },
  {
    path: '/transaction-list-details',
    element: <TransactionListPage />,
    loadTime: 4,
  },
  {
    path: '/transaction-details',
    element: <TransactionDetailPage />,
    loadTime: 4,
  },
];

const DevPath = [
  { path: '/sendmessage', element: <SendPostMessage /> },
  { path: '/listenmessage', element: <ListenPostMessage /> },
  { path: '/dummyfetch', element: <DummyFetch /> },
  { path: '/info', element: <DeployInfo /> },
];

const Routes = () => {
  return (
    <>
      <Suspense fallback={<ReSimpleCircularProgress />}>
        <ReactRoutes>
          <Route path="/" element={<ListPath paths={RoutePaths} />} />
          {RoutePaths.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                !route.noPageLoad ? (
                  <PageLoadMeasure path={route.path} load={route.loadTime}>
                    {route.element}
                  </PageLoadMeasure>
                ) : (
                  route.element
                )
              }
            />
          ))}

          {process.env.REACT_APP_ENV !== 'production' &&
            DevPath.map((devpath) => (
              <Route
                key={devpath.path}
                path={devpath.path}
                element={devpath.element}
              />
            ))}
        </ReactRoutes>
      </Suspense>
    </>
  );
};

export default Routes;
