import { milisecToSec } from 'functions/functions';
import { logInfo } from 'functions/logging';
import { getLSProfile } from 'localstorage/getter';
import React, { useEffect } from 'react';
import { sendGChat } from 'utils/googlechat';
import { savePerformance } from 'utils/perf';

const getOutlet = () => {
  // TODO tidak bisa selalu mengandalkan URL id, karena id bisa apapun bukan outlet_id saja
  // di split karena tidak bisa dapat parameter pertama setelah ?
  const outlet_id = new URLSearchParams(
    `?${window.location.href.split('?')[1]}`,
  ).get('id');

  return outlet_id;
};

const PageLoadMeasure = ({ children, path, load }) => {
  useEffect(() => {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        const navigationTimings = performance.getEntriesByType('navigation')[0];

        // if (milisecToSec(navigationTimings.domComplete) >= load) {
        //   sendGChat('LOAD OVER TIME', [
        //     { label: 'PATH', value: path },
        //     {
        //       label: 'LOAD TIME',
        //       value: `${milisecToSec(navigationTimings.domComplete)} sec`,
        //     },
        //   ]);
        // }

        if (!process.env.REACT_APP_ENV.toLowerCase().includes('local')) {
          savePerformance({
            type: 'PAGE',
            page: path,
            destination: path,
            date_time: new Date(),
            time: milisecToSec(navigationTimings.domComplete),
            user: getLSProfile()?.user_id,
            outlet: getOutlet() || null,
          });
        }

        logInfo(
          `>> PAGELOAD ${path} TOOK :  ${milisecToSec(
            navigationTimings.domComplete,
          )} sec <<`,
        );
      }
    };
  }, []);

  return <>{children}</>;
};

export default PageLoadMeasure;
