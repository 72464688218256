// sengaja dibuat warna sendiri karena diluar app,
// hanya untuk info developer

import { getLSDisableLog } from 'localstorage/getter';

export const logColor = {
  success: 'background: #fff400; color: #000',
  error: 'background: #ff6659; color: #fff',
  info: 'background: #001bff; color: #bcbed0',
};

export const logInfo = (value) => {
  if (process.env.REACT_APP_ENV !== 'production') {
    console.log(`%c ${value}`, logColor.info);
  }
};

export const logPayloadResponse = (func, payload, response, status) => {
  const disableLog = getLSDisableLog();
  const disableLogging = disableLog || false;
  if (process.env.REACT_APP_ENV !== 'production' && !disableLogging) {
    let localStatus = 'success';
    if (
      response &&
      (response.status === false ||
        response.error === true ||
        status === 'error')
    ) {
      localStatus = 'error';
    }
    console.log(
      `%c ⇓⇓⇓⇓⇓⇓⇓⇓⇓⇓⇓⇓⇓⇓ REPORT START ${func} ⇓⇓⇓⇓⇓⇓⇓⇓⇓⇓⇓⇓⇓⇓`,
      logColor[localStatus],
    );

    console.log(`STATUS : ${localStatus.toUpperCase()}`);
    console.log(`FUNCTION : ${func}`);
    console.log('PAYLOAD :');
    console.log(`${JSON.stringify(payload)}`, null, 4);
    if (status === 'success') {
      console.log('RESPONSE :');
      console.log(response);
    }

    console.log(
      `%c ⇑⇑⇑⇑⇑⇑⇑⇑⇑⇑⇑⇑⇑⇑ REPORT END ${func} ⇑⇑⇑⇑⇑⇑⇑⇑⇑⇑⇑⇑⇑⇑`,
      logColor[localStatus],
    );
  }
};
