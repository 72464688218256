const webhookURL =
  'https://chat.googleapis.com/v1/spaces/AAAAn--tjI4/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=-32j_hm0YIbvQ1UcqkvdjdWQp6Y97Gc73_0CcI-cthw%3D';
const moment = require('moment-timezone');

// eslint-disable-next-line import/prefer-default-export
export const sendGChat = (title, body) => {
  const data = JSON.stringify({
    cards: [
      {
        header: {
          title,
        },
        sections: [
          {
            widgets: [
              {
                keyValue: {
                  topLabel: 'Date',
                  content: moment(new Date()).format('DD/MM/YYYY HH:mm'),
                  contentMultiline: 'true',
                },
              },
              body.map((item) => {
                return {
                  keyValue: {
                    topLabel: item.label,
                    content: item.value,
                    contentMultiline: 'true',
                  },
                };
              }),
            ],
          },
        ],
      },
    ],
  });

  fetch(webhookURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: data,
  }).then(() => {
    return true;
  });
};
