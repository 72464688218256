import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

const ReSimpleCircularProgress = () => (
  <Grid
    style={{
      width: '100%',
      height: '100vh',
    }}>
    <CircularProgress
      style={{
        position: 'absolute',
        left: '50%',
        top: '45%',
      }}
    />
  </Grid>
);

export default ReSimpleCircularProgress;
