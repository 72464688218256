import React from 'react';
import { Box } from '@material-ui/core';

const ListPath = (props) => {
  return (
    <Box>
      <h2>LIST PATH YANG BISA DI PAKAI :</h2>
      <ol style={{ display: 'inline-block', margin: '0 auto' }}>
        {props.paths.map((route) => (
          <li>{route.path}</li>
        ))}
      </ol>
    </Box>
  );
};

export default ListPath;
