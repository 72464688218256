import { Box } from '@material-ui/core';
import React, { useState, useContext } from 'react';

/* ===========> BACA DULU SEBELUM EDIT <===========

TOLONG JANGAN MASUKKAN RANDOM VARIABLE KE SINI, ATAU KONSULTASIKAN KE MAINTAINER BO

===========> BACA DULU SEBELUM EDIT <=========== */

export const AuthContext = React.createContext([{}, () => {}]);

const AuthProvider = (props) => {
  const [state, setState] = useState({});

  const setAuthState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getAuthState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  return (
    <AuthContext.Provider
      value={{
        getAuthState,
        setAuthState,
      }}>
      <Box
        style={{
          height: '100vh',
          width: '100%',
        }}>
        {props.children}
      </Box>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const value = useContext(AuthContext);
  if (value == null) {
    throw new Error('useAuthContext() called outside of a PosProvider?');
  }
  return value;
};

export default AuthProvider;
