import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

// eslint-disable-next-line import/no-mutable-exports
let ThemeStyles = createTheme({
  palette: {
    primary: {
      main: '#0954D3',
    },
    secondary: {
      main: '#C96909',
    },
    error: {
      main: '#B00020',
    },
    warning: {
      main: '#E2CF21',
    },
    success: {
      main: '#02A328',
    },
    disabled: {
      main: '#AEBAD0',
      text: '#FFFFFF',
      mainOpacity50: 'rgba(174, 186, 208, 0.5)',
    },
  },
  customColor: {
    text: {
      primary: '#001E6B',
      secondary: '#FFFFFF',
      placeholder: '#0777D3',
      primaryOpacity75: 'rgba(0, 30, 107, 0.75)',
    },
    background: {
      primary: '#D9E7F2',
      secondary: '#FFFFFF',
      header: '#0777D3',
      headerOpacity50: 'rgba(7, 119, 211, 0.5)',
      secondaryOpacity: 'rgba(255, 255, 255, 0.3)',
      blackOpacity: 'rgba(0,0,0,0.87)',
      backdrop: 'rgba(0, 30, 107,0.5)',
      borderContent: 'rgba(0, 30, 107, 0.24)',
      onHover: '#F78411',
    },
  },
  typography: {
    fontFamily: ['"Poppins-Light"'].join(','),
  },
  customFont: {
    poppinsRegular: {
      fontFamily: ['"Poppins-Regular"'].join(','),
    },
    poppinsMedium: {
      fontFamily: ['"Poppins-Medium"'].join(','),
    },
  },
  spacing: 8,
  drawerWidth: 330,
  filterWidth: 445,
  bottomOffset: 150,
  buttonWidth: 158,
  maxHeightPageContainer: 'calc(100vh - 120px)',
});

ThemeStyles = responsiveFontSizes(ThemeStyles);

export default ThemeStyles;
