import { milisecToSec } from 'functions/functions';
import { logInfo } from 'functions/logging';
import { getLSProfile } from 'localstorage/getter';

const constructData = (data) => {
  const template = {
    source: 'REPORT',
    load: data.type,
    page: window.location.pathname,
    destination: data.destination,
    date_time: new Date(),
    // eslint-disable-next-line no-use-before-define
    time: data instanceof Perf ? milisecToSec(data.result()) : data.time,
    user: getLSProfile()?.user_id,
    outlet: data.payload?.filter?.outlet_id,
    description: data.payload,
  };

  return template;
};

export const savePerformance = async (data) => {
  const EXEC_WH =
    'https://ap-southeast-1.aws.data.mongodb-api.com/app/exectime-ilrjd/endpoint/webHooks/InsertExecTime';

  const payload = constructData(data);

  try {
    const getResponse = await fetch(EXEC_WH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const res = await getResponse.json();
    return res;
  } catch (e) {
    // handle network error
    return { status: false, message: e.toString() };
  }
};

class Perf {
  constructor(type, destination, payload) {
    this.type = type;
    this.destination = destination;
    this.payload = payload;
  }

  result() {
    return this.time?.end - this.time?.start;
  }

  start() {
    this.time = {
      start: performance.now(),
    };
  }

  end() {
    this.time = {
      ...this.time,
      end: performance.now(),
    };

    logInfo(
      `== SERVER LOAD ${this.destination} TOOK : ${milisecToSec(
        this.result(),
      )} sec ==`,
    );

    if (!process.env.REACT_APP_ENV.toLowerCase().includes('local')) {
      savePerformance(this);
    }

    return this.result() / 1000;
  }
}

export default Perf;
