import { LSname } from 'localstorage';

export const setLocalStorage = (key, value, raw) => {
  if (raw) localStorage.setItem(key, value);
  else localStorage.setItem(key, JSON.stringify(value));
};

export const setLSReportAccess = (value) => {
  setLocalStorage(LSname.report_access, value);
};

export const setLSProfile = (value) => {
  setLocalStorage(LSname.profile, value);
};

export const setLSDisableLog = (value) => {
  setLocalStorage(LSname.disable_report_log, value, true);
};

// CLEAR
export const clearLocalStorage = () => {
  localStorage.clear();
};
