import React, { useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from 'context/AuthContext';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SentryInfo, SentryLevel } from 'utils/sentry';
import ThemeStyles from './ThemeStyles';
import Routes from './Routes';

let prefixRelease = 'BO_Report';
if (process.env.REACT_APP_ENV) {
  prefixRelease = prefixRelease.concat(
    `_${process.env.REACT_APP_ENV.toUpperCase()}`,
  );
}

const App = () => {
  useEffect(() => {
    if (!document.title.includes(process.env.REACT_APP_ENV)) {
      document.title = `${process.env.REACT_APP_ENV} - ${document.title}`;
    }

    if (!process.env.REACT_APP_ENV.toLowerCase().includes('local')) {
      Sentry.init({
        dsn: 'https://a66515932e144120bd93977d542d56af@sentry.mgc.pw/7',
        integrations: [new BrowserTracing()],
        release: `${prefixRelease}@${process.env.REACT_APP_VERSION}`,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: process.env.REACT_APP_SENTRY_ENV || 'LOCAL',
        initialScope: (scope) => {
          scope.setTags({ errorType: 'UNHANDLED ERROR' });
          scope.setLevel(SentryLevel.Fatal);
          scope.setTags(SentryInfo().tags);
          scope.setUser(SentryInfo().user);

          return scope;
        },
      });
    }
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={ThemeStyles}>
        <AuthProvider>
          <Router>
            <Routes />
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
