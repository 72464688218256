import { getLSReportAccess } from 'localstorage/getter';
import { clearLocalStorage, setLSReportAccess } from 'localstorage/setter';

export const reportAccessSetter = (newAccess) => {
  if (newAccess) {
    const currentAccess = getLSReportAccess();
    if (currentAccess !== newAccess) {
      clearLocalStorage();
      setLSReportAccess(newAccess);
    }
  }
};

export const convertNumber = (number) => {
  const locale = 'id-ID';
  return Math.round(+number)?.toLocaleString(locale);
};

export const minusToBeBrackets = (number) => {
  const localNumber = number.toString();
  if (localNumber.includes('-')) {
    return localNumber.replace('-', '(').concat(')');
  }
  return number;
};

export const capitalizeFirstString = (string) => {
  return string && string[0].toUpperCase() + string.slice(1);
};

// GET QUERY STRING FROM THE URL ex: /verify?name=john => ?name=john
export const getLocationQuery = (location, parameter) => {
  if (parameter) {
    return new URLSearchParams(location.search).get(parameter);
  }
  return location.search;
};

export const convertNumberToMonthName = (number) => {
  const date = new Date();
  date.setMonth(+number);

  return date.toLocaleString('en-US', {
    month: 'long',
  });
};

export const getFilterMinMax = (paramData, minValue, maxValue, field) => {
  const localData = Array.isArray(paramData) ? paramData : paramData?.data;

  if (localData?.length > 0) {
    return localData.filter((item) => {
      let result = item;
      if (minValue && maxValue) {
        result = item?.[field] >= minValue && item?.[field] <= maxValue;
      } else if (minValue) {
        result = item?.[field] >= minValue;
      } else if (maxValue) {
        result = item?.[field] <= maxValue;
      }
      return result;
    });
  }
};

export const sumTotalArray = (paramData, key) => {
  if (Array.isArray(paramData)) {
    return paramData.reduce((acc, curr) => {
      return +curr[key] + +acc;
    }, 0);
  }

  return null;
};

export const reCalculateRowTotal = (data, field) => {
  // data = data server, field = array of key
  const resultTotal = {};
  field.forEach((item) => {
    resultTotal[item] = sumTotalArray(data, item);
  });

  return resultTotal;
};

export const milisecToSec = (value) => {
  return (value / 1000).toFixed(2);
};

export const cloneData = (data) => {
  return JSON.parse(JSON.stringify(data));
};
